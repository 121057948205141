* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  padding: 0;
  background-color: #fafafa;
  font-family: 'Mukta', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

img[class*='wp-image'] {
  max-width: 100% !important;
  height: auto;
  max-height: 100vh !important;
  object-fit: contain;
}
